import { useCallback } from "react";
import { useParams } from "react-router-dom";

import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Radio,
  Checkbox,
  ListItemButton,
} from "@mui/material";
import { CONTENT_OBJ } from "utils/contentUtils";
import useQueryNav from "hooks/useQueryNav";
import { QUERY_PARAMS_OBJ, valueParser } from "utils/navUtils";
import PaneTitle from "Navigation/Components/PaneTitle";

import { usePaneFuncs } from "contexts/PaneContext";
import useParamNav from "hooks/useParamNav";

import { PARAM_ROUTES_OBJ } from "utils/navUtils";
import { PANE_TYPES } from "Navigation/Panes";
import { useGeneral } from "contexts/GeneralContext";
import { useData } from "contexts/DataContext";

const LAYERS = PARAM_ROUTES_OBJ.version.expectedValue.values;

const Layers = () => {
  const { dynamicStyle } = useGeneral();
  const data = useData();
  const { version, product, style: styleNumber } = useParams();
  const { searchParams, queryNav } = useQueryNav();

  const layer = QUERY_PARAMS_OBJ?.["ol"];

  let style = data[CONTENT_OBJ[version][product]]?.raster?.styles[styleNumber];
  if (!style) {
    return null;
  }

  if (style.method === "dynamicStyle") {
    style = dynamicStyle;
  }

  return (
    <>
      <PaneTitle>Lagen</PaneTitle>
      <List>
        <ListItem sx={{ p: 0 }}>
          <ListItemButton
            onClick={() => {
              queryNav({ ex: searchParams?.["ex"] === "kb" ? null : "kb" });
            }}
          >
            <ListItemIcon>
              <Checkbox checked={searchParams?.["ex"] === "kb"} disableRipple />
            </ListItemIcon>
            <ListItemText primary={"Kaartbladen"} />
          </ListItemButton>
        </ListItem>
        <Divider />
        {LAYERS?.map((layer) => (
          <ParamLayer key={layer} layer={layer} />
        ))}
        <Divider />

        <ListItem sx={{ p: 0 }}>
          <ListItemButton
            onClick={() =>
              queryNav({
                ol: !valueParser({
                  inputValue: searchParams?.["ol"],
                  ...QUERY_PARAMS_OBJ["ol"],
                }),
              })
            }
          >
            <ListItemIcon>
              <Checkbox
                disableRipple
                checked={valueParser({
                  inputValue: searchParams?.["ol"],
                  ...QUERY_PARAMS_OBJ["ol"],
                })}
              />
            </ListItemIcon>
            <ListItemText primary={layer?.label} />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );
};

export default Layers;

const ParamLayer = ({ layer }) => {
  const { openPane } = usePaneFuncs();
  const { version, product } = useParams();
  const { paramNav } = useParamNav();

  const selectLayer = useCallback(() => {
    if (layer !== "AHN4" && product === "Geïnterpoleerd DTM") {
      paramNav({ version: layer, style: 0, product: "DTM" });
    } else {
      paramNav({ version: layer });
    }
  }, [layer, paramNav, product]);

  return (
    <ListItem
      sx={{ p: 0, pr: 10 }}
      secondaryAction={
        <Button
          onClick={() => {
            selectLayer();
            openPane({ type: PANE_TYPES["individualLayer"], layer });
          }}
          sx={{ mr: -1 }}
        >
          meer
        </Button>
      }
    >
      <ListItemButton onClick={selectLayer}>
        <ListItemIcon>
          <Radio checked={layer === version} disableRipple />
        </ListItemIcon>
        <ListItemText primary={layer} />
      </ListItemButton>
    </ListItem>
  );
};
