import { memo, useCallback } from "react";

import { Fab } from "@mui/material";
import { MyLocation, MyLocationOutlined } from "@mui/icons-material";

import FadingIcon from "ReusableComponents/FadingIcon";
import { useGeneral } from "contexts/GeneralContext";

const LocationButton = () => {
  const { setFlyTo, setSnackbar } = useGeneral();

  const handleClick = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      (x) => {
        if (!x) {
          setSnackbar({
            content: "Kon uw locatie niet bepalen",
            level: "error",
          });
          return;
        }
        setFlyTo({
          type: "Point",
          coordinates: [x.coords.longitude, x.coords.latitude],
        });
      },
      (error) => {
        setSnackbar({
          content: "Kon uw locatie niet bepalen",
          level: "error",
        });
        console.error(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
      }
    );
  }, [setFlyTo, setSnackbar]);

  return (
    <Fab size="small" onClick={handleClick}>
      <FadingIcon icons={[MyLocationOutlined, MyLocation]} />
    </Fab>
  );
};

export default memo(LocationButton);
