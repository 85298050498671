import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";
import { CONTENT_OBJ } from "utils/contentUtils";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Typography,
} from "@mui/material";
import useParamNav from "hooks/useParamNav";
import { useData } from "contexts/DataContext";

const Styles = memo(() => {
  const { version, product, style: styleNumber } = useParams();
  const { paramNav } = useParamNav();

  const selectStyle = useCallback((i) => paramNav({ style: i }), [paramNav]);

  const data = useData();

  if (!data) return null;
  const layer = data[CONTENT_OBJ?.[version]?.[product]];
  if (!layer) return;

  let styles = [...layer.raster.styles];
  if (product === "Geïnterpoleerd DTM") {
    styles = styles.filter((s) => s.method !== "dynamicStyle");
  }

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Kaart Stijlen
      </Typography>
      <List disablePadding>
        {styles?.map((s, i) => {
          // eslint-disable-next-line eqeqeq
          const selected = styleNumber == i;
          return (
            <ListItem key={"style_" + i + "_" + selected} sx={{ p: 0 }}>
              <ListItemButton sx={{ mx: -2 }} onClick={() => selectStyle(i)}>
                <ListItemIcon>
                  <Radio checked={selected} />
                </ListItemIcon>
                <ListItemText primary={s?.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
});

export default Styles;
