import { createContext, useContext, useMemo, useState } from "react";

const generalContext = createContext();

export const styleSjabloon = {
  parameters: {
    alpha: 1,
    bandNumber: 1,
    transitionPoints: [
      { value: 0, color: "#0062B1" },
      { value: 11.11111111111111, color: "#009CE0" },
      { value: 22.22222222222222, color: "#73D8FF" },
      { value: 33.33333333333333, color: "#808900" },
      { value: 44.44444444444444, color: "#A4DD00" },
      { value: 55.55555555555556, color: "#A4DD00" },
      { value: 66.66666666666666, color: "#FCDC00" },
      { value: 77.77777777777777, color: "#FB9E00" },
      { value: 88.88888888888889, color: "#D33115" },
      { value: 100, color: "#9F0500" },
    ],
    continuous: true,
    shade: {
      factor: 0.4,
      exaggerate: 10,
      angle: 45,

      bandNumber: 1,
    },
  },
  method: "bandToColor",
  name: "Hoogte",
};

export const GeneralProvider = ({ children }) => {
  const [flyTo, setFlyTo] = useState();
  const [snackbar, setSnackbar] = useState();
  const [dynamicStyle, setDynamicStyle] = useState(null);

  const value = useMemo(
    () => ({
      flyTo,
      setFlyTo,
      snackbar,
      setSnackbar,
      dynamicStyle,
      setDynamicStyle,
    }),
    [flyTo, setFlyTo, snackbar, setSnackbar, dynamicStyle, setDynamicStyle]
  );

  return (
    <generalContext.Provider value={value}>{children}</generalContext.Provider>
  );
};

export const useGeneral = () => {
  const context = useContext(generalContext);

  if (context === undefined) {
    throw new Error("useGeneral must be used within a AnalyseProvider");
  }
  return context;
};
