export const CONTENT_FOLDER_ID = "2d3eb7bf-4a3c-4692-8237-904ac4452181";

export const CONTENT_OBJ = {
  AHN2: {
    DSM: "28fb0f5f-e367-4265-b84b-1b8f1a8a6409",
    DTM: "e96b10b9-e964-414c-958c-57a9dbe24e62",
    "Geïnterpoleerd DTM": "0ec9c67d-666e-440d-b47a-02a67587dfa6",
    vl: "74049623-1d09-4b35-80d3-f09ce422ed73",
    omhullen: "02cf6582-0a22-4d97-af3b-1f5c1314f0dc",
  },
  AHN3: {
    DSM: "94037eea-4196-48db-9f83-0ef330a7655e",
    DTM: "69f81443-c000-4479-b08f-2078e3570394",
    "Geïnterpoleerd DTM": "0ec9c67d-666e-440d-b47a-02a67587dfa6",
    vl: "487fd1e1-7ed2-4b3d-b648-12579b08454e",
    omhullen: "a13516c3-d62b-4a80-867b-c5dbad50625a",
  },
  AHN4: {
    DSM: "78080fff-8bcb-4258-bb43-be9de956b3e0",
    DTM: "8b60a159-42ed-480c-ba86-7f181dcf4b8a",
    "Geïnterpoleerd DTM": "0ec9c67d-666e-440d-b47a-02a67587dfa6",
    vl: "dea9cb6a-396f-4ace-b040-4b100556add5",
    omhullen: "efe57057-75d9-4f9d-be15-08b4621648b3",
  },
  AHN5: {
    DSM: "a4a8a27b-e36e-4dd5-a75b-f7b6c18d33ec",
    DTM: "51e11c02-1065-463d-a0b0-263d80293b16",
    "Geïnterpoleerd DTM": "0ec9c67d-666e-440d-b47a-02a67587dfa6",
    vl: "56f7b7ef-0c71-4f58-9451-d6fc390c8154",
    omhullen: "4016c4d5-c5c2-46af-88d0-e13be6fcd73a",
  },
};
