import { memo, useCallback } from "react";
import { useParams } from "react-router-dom";

import { Box, ListItemText, MenuItem, Select, Typography } from "@mui/material";

import useParamNav from "hooks/useParamNav";

import { PARAM_ROUTES_OBJ, PRODUCT_OBJ } from "utils/navUtils";

const Product = memo(() => {
  const { product, version } = useParams();
  const { paramNav } = useParamNav();

  const handleProductChange = useCallback(
    (e) => {
      if (e?.target?.value === "Geïnterpoleerd DTM") {
        paramNav({ product: e?.target?.value, style: 0 });
      } else {
        paramNav({ product: e?.target?.value });
      }
    },
    [paramNav]
  );
  let products = PARAM_ROUTES_OBJ?.product?.expectedValue?.values;
  if (version !== "AHN4") {
    products = products.filter((p) => p !== "Geïnterpoleerd DTM");
  }

  return (
    <Box>
      <Typography variant="h2" gutterBottom>
        Kies een product
      </Typography>
      <Select
        value={product}
        onChange={handleProductChange}
        fullWidth
        renderValue={(value) => value}
      >
        {products?.map((p) => (
          <MenuItem key={p} value={p}>
            <ListItemText primary={p} secondary={PRODUCT_OBJ[p]} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
});

export default Product;
