import Proj4 from "proj4";

const RDXmin = -285401.92;
const RDYmin = 22598.080000000075;
const RDXMax = 595401.9199999999;
const RDYMax = 903401.92;
const LEN = 20037508.342789244;

const defineCRS = () => {
  Proj4.defs(
    "EPSG:28992",
    "+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +units=m +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +no_defs"
  );
  Proj4.defs(
    "EPSG:3857",
    "+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +k=1.0 +units=m +nadgrids=@null +wktext  +no_defs"
  );
};

defineCRS();

function LatLonToMercator(lon, lat) {
  const y =
    ((2 * LEN) / (2 * Math.PI)) *
      (Math.PI - Math.log(Math.tan(Math.PI / 4 + (lat / 360) * Math.PI))) -
    LEN;

  const x = (2 * LEN * (lon + 180)) / 360 - LEN;
  return [x, y];
}

function MercatorToLatLon(mercX, mercY) {
  var rMajor = 6378137; //Equatorial Radius, WGS84
  var shift = Math.PI * rMajor;
  var lon = (mercX / shift) * 180.0;
  var lat = (mercY / shift) * 180.0;

  lat =
    (180 / Math.PI) *
    (2 * Math.atan(Math.exp((lat * Math.PI) / 180.0)) - Math.PI / 2.0);

  return { lon, lat };
}

export const reproject = (feature) => {
  const f = (c, reverse) => {
    const cmerc = Proj4("EPSG:28992", Proj4.WGS84, [c[0], c[1]]);
    return cmerc;
  };

  let newCoords;
  if (feature.geometry.type === "Point") {
    newCoords = f(feature.geometry.coordinates);
  } else if (feature.geometry.type === "LineString") {
    newCoords = feature.geometry.coordinates.map((c) => f(c));
  } else if (feature.geometry.type === "MultiPolygon") {
    newCoords = feature.geometry.coordinates.map((p) => [
      p[0].map((c) => f(c)),
    ]);
  } else if (feature.geometry.type === "MultiLineString") {
    newCoords = feature.geometry.coordinates.map((l) => l?.map((c) => f(c)));
  } else {
    newCoords = [feature.geometry.coordinates[0].map((c) => f(c))];
  }

  const newFeature = {
    ...feature,
    geometry: { ...feature.geometry, coordinates: newCoords },
  };

  return newFeature;
};

export const manipulateCoords = (latLng, feature, reverse = false) => {
  let type = "feature";
  if (!feature) {
    type = "latLng";
    feature = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [latLng.lng, latLng.lat],
      },
      properties: {},
    };
  }
  //receive it in WGS84
  //project to RD

  const f = (c, reverse) => {
    if (reverse) {
      const cmerc = Proj4(Proj4.WGS84, "EPSG:3857", [c[0], c[1]]);

      const RDX = RDXmin + ((RDXMax - RDXmin) * (cmerc[0] + LEN)) / (2 * LEN);
      const RDY = RDYmin + ((RDYMax - RDYmin) * (cmerc[1] + LEN)) / (2 * LEN);
      const y = Proj4("EPSG:28992", Proj4.WGS84, [RDX, RDY]);
      return y;
    } else {
      const y = Proj4(Proj4.WGS84, "EPSG:28992", c);
      const mercX = ((y[0] - RDXmin) / (RDXMax - RDXmin)) * 2 * LEN - LEN;
      const mercY = ((y[1] - RDYmin) / (RDYMax - RDYmin)) * 2 * LEN - LEN;

      const cWgs = Proj4("EPSG:3857", Proj4.WGS84, [mercX, mercY]);

      return [cWgs[0], cWgs[1]];
    }
  };
  let newCoords;
  if (feature.geometry.type === "Point") {
    newCoords = f(feature.geometry.coordinates, reverse);
  } else if (feature.geometry.type === "LineString") {
    newCoords = feature.geometry.coordinates.map((c) => f(c, reverse));
  } else if (feature.geometry.type === "MultiPolygon") {
    newCoords = feature.geometry.coordinates.map((p) => [
      p[0].map((c) => f(c, reverse)),
    ]);
  } else if (feature.geometry.type === "MultiLineString") {
    newCoords = feature.geometry.coordinates.map((l) =>
      l?.map((c) => f(c, reverse))
    );
  } else {
    newCoords = [feature.geometry.coordinates[0].map((c) => f(c, reverse))];
  }

  const newFeature = {
    ...feature,
    geometry: { ...feature.geometry, coordinates: newCoords },
  };

  if (type === "feature") {
    return newFeature;
  } else {
    return {
      lat: newFeature.geometry.coordinates[1],
      lng: newFeature.geometry.coordinates[0],
    };
  }
};
